interface ILiffTypes {
  researchFlight: string;
  researchHotel: string;
  top: string;
  lineEntry: string;
}

export const LiffTypes: ILiffTypes = {
  researchFlight: "researchFlight",
  researchHotel: "researchHotel",
  top: "top",
  lineEntry: "lineEntry",
};
