import { useEffect, useState, useRef } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  LINE_LIFF_SDK_URL,
  // FACEBOOK_MESSENGER_SDK_URL,
  TOP_LIFF_ID,
  LINE_ENTRY_LIFF_ID,
} from "main/javascripts/constants/Constants";
import { fetchAccountParams } from "main/javascripts/slices/accountParamSlice";
import { LiffTypes } from "main/javascripts/constants/LiffTypes";
import { useRouter } from "next/router";
import {
  // signInFacebookUser,
  signInLineUser,
  setIsLIFFInitialized,
  // setIsFacebookMessengerInitialized,
  setIsLIFFLoggedIn,
  // setIsFacebookMessengerLoggedIn,
} from "main/javascripts/slices/currentUserSlice";

// useSocialAutoSignInのredux toolkit版
// initializedとloggedInを分けて管理しているため注意

// LINEから着地するページで使う(useAccountとの違い)
// Login + initをしておく(Loginはcookieで次のページにも引き継がれる)
export const useUserSocialAutoSignIn: any = (
  store: any,
  liffIdType: string,
  notIssueSession: boolean | undefined
): any => {
  const router = useRouter();
  const [isLoadedLIFF, setIsLoadedLIFF] = useState(false);
  // const [isLoadedFBM, setIsLoadedFBM] = useState(false);
  const [isLoadedAccount, setIsLoadedAccount] = useState(false);
  const [isLIFFInitializedState, setIsLIFFInitializedState] = useState(false);
  const [isLIFFLoggedInState, setIsLIFFLoggedInState] = useState(false);
  const [
    isFacebookMessengerInitializedState,
    // setIsFacebookMessengerInitializedState,
  ] = useState(false);
  const [
    isFacebookMessengerLoggedInState,
    // setIsFacebookMessengerLoggedInState,
  ] = useState(false);
  const [liffError, setLiffError] = useState(null);
  const [
    facebookError,
    // setFacebookError
  ] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const researchFlightLiffId = useRef("");
  const researchHotelLiffId = useRef("");
  const facebookAppId = useRef("");

  const onLoadScriptLIFF = () => {
    const context = liff?.getOS();
    if (context) {
      setIsLoadedLIFF(true);
    }
  };

  // const onLoadScriptFBM = () => {
  //   setIsLoadedFBM(true);
  // };

  const loadScript = (url: string, onload?: any): void => {
    const script: HTMLScriptElement = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = onload ? onload : null;
    document.body.appendChild(script);
  };

  // LIFF IDが必要なためここでAccountParamsを取得する
  const loadAccount = async () => {
    let param = "";
    const t_session_id = router.query.t_session_id;
    if (t_session_id) {
      param += `t_session_id=${t_session_id}`;
    }
    if (notIssueSession && !t_session_id) {
      param += param === "" ? "" : "&";
      param += `not_issue_session=${notIssueSession}`;
    }
    store
      .dispatch(fetchAccountParams({ param: param }))
      .then(unwrapResult)
      .then((result) => {
        const accountParams = result;
        if (accountParams) {
          const newCurrentUser = accountParams.currentUser;
          setCurrentUser(newCurrentUser);
          researchFlightLiffId.current = accountParams.researchFlightLiffId;
          researchHotelLiffId.current = accountParams.researchHotelLiffId;
          facebookAppId.current = accountParams.facebookAppId;
          setIsLoadedAccount(true);
        }
      });
  };

  useEffect(() => {
    if (router.isReady) {
      loadAccount().catch((e) => {
        console.error(e);
      });
    }
  }, [router.isReady]);

  useEffect(() => {
    if (!isLoadedLIFF) {
      if (typeof liff === "undefined") {
        loadScript(LINE_LIFF_SDK_URL, onLoadScriptLIFF);
      } else {
        setIsLoadedLIFF(true);
      }
    } else {
      if (isLoadedAccount) {
        initLiff();
      }
    }
  }, [isLoadedLIFF, isLoadedAccount]);

  // FacebookMessengerは現在使用していない
  // useEffect(() => {
  //   // SDKが読み込まれた後でextAsyncInitを設定しても呼ばれないため
  //   // SDKがロード済みかどうか判定する必要がある
  //   if (!isLoadedFBM) {
  //     if (!(window as any).MessengerExtensions) {
  //       waitFacebookMessengerScriptLoaded();
  //       loadScript(FACEBOOK_MESSENGER_SDK_URL);
  //     } else {
  //       setIsLoadedFBM(true);
  //     }
  //   } else {
  //     if (isLoadedAccount) {
  //       initFacebookMessengerExtension();
  //     }
  //   }
  // }, [isLoadedFBM, isLoadedAccount]);

  // const initFacebookMessengerExtension = (): any => {
  //   console.log("initFacebookMessengerExtension");
  //   (window as any).MessengerExtensions.getContext(
  //     facebookAppId.current,
  //     (result: any): void => {
  //       store
  //         .dispatch(signInFacebookUser(result))
  //         .then(unwrapResult)
  //         .then(() => {
  //           store.dispatch(setIsFacebookMessengerLoggedIn(true));
  //           setIsFacebookMessengerLoggedInState(true);
  //         });
  //       store.dispatch(setIsFacebookMessengerInitialized(true));
  //       setIsFacebookMessengerInitializedState(true);
  //     },
  //     (err: any): void => {
  //       console.log("FBM ERROR ", err);
  //       setFacebookError(err);
  //     }
  //   );
  // };
  //
  // const waitFacebookMessengerScriptLoaded = (): any => {
  //   // console.log("waitFacebookMessengerScriptLoaded");
  //   (window as any).extAsyncInit = (): void => {
  //     // console.log(" extAsyncInit");
  //     // FBはscriptのonloadではなくextAsyncInitでload完了とみなす
  //     onLoadScriptFBM();
  //   };
  // };

  const getLiffId = (): string => {
    if (liffIdType === LiffTypes.researchFlight) {
      return researchFlightLiffId.current;
    } else if (liffIdType === LiffTypes.researchHotel) {
      return researchHotelLiffId.current;
    } else if (liffIdType === LiffTypes.top) {
      return TOP_LIFF_ID;
    } else if (liffIdType === LiffTypes.lineEntry) {
      return LINE_ENTRY_LIFF_ID;
    }
    return null;
  };

  const initializeLIFFState = () => {
    store.dispatch(setIsLIFFInitialized(true));
    setIsLIFFInitializedState(true);
  };

  const initLiff = (): any => {
    const liffId = getLiffId();
    if (!liffId) {
      return;
    }

    // store.dispatch(setIsLIFF(liff.isInClient())); // v2

    if (liff.id && liff.isLoggedIn()) {
      // すでに初期化済み
      store.dispatch(setIsLIFFInitialized(true));
      store.dispatch(setIsLIFFLoggedIn(true));
      setIsLIFFInitializedState(true);
      setIsLIFFLoggedInState(true);
      return;
    }
    liff
      .init({
        liffId: liffId,
      })
      .then(
        (_: any) => {
          // Now you can call LIFF API
          const accessToken: string = liff.getAccessToken();
          console.log(`accessToken: ${accessToken}`);
          if (accessToken) {
            console.log("AccessToken: ", accessToken);
            console.log("CREATE_LINE_AUTO_SIGN_IN");
            store
              .dispatch(signInLineUser({ accessToken: accessToken }))
              .then(unwrapResult)
              .then(() => {
                // サインイン成功時initializedとloggedInは
                // 同時に設定されることを前提にしている
                initializeLIFFState();
                store.dispatch(setIsLIFFLoggedIn(true));
                setIsLIFFLoggedInState(true);
              })
              .catch(() => {
                // エラー時はinitializedのみ設定
                initializeLIFFState();
              });
          } else {
            // accessTokenが取得できない時もinitializedのみ設定
            initializeLIFFState();
          }
        },
        (err: any) => {
          // LIFF initialization failed
          console.log("liff initialization failed", err);
          setLiffError(err);
        }
      );
  };

  return [
    isLIFFInitializedState,
    isLIFFLoggedInState,
    isFacebookMessengerInitializedState,
    isFacebookMessengerLoggedInState,
    liffError,
    facebookError,
    isLoadedAccount,
    currentUser,
  ];
};
